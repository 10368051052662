.desc {
  color: #595d6e;
  font-size: 14px;
  margin-bottom: 13px;
}
.desc.program-label{
  color: #404040;
  font-size: 13px;
  margin-bottom: 6px;
}
.btn-link {
  color: #207ebb !important;
  font-size: 14px;
}

.file-upload-wrapper {
  position: relative;
  overflow: hidden;
  background: #f8f8f8;
  border: 1px solid #dedede;
  padding-left: 10px;
  height: 30px;
  border-radius: 5px;

  span {
    margin: 0px;
    font-size: 10px;
    color: #595d6e;
  }

  input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
  }

  &.drag-upload {
    border: 1px solid #dedede;
    margin-top: 10px;
    background: #fff;

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin: 0;
      font-size: 10px !important;
    }
  }
}

.upload-overlay {
  .desc {
    color: #d8d8d8;
  }

  .file-upload-wrapper {
    border-color: #f8f8f8;

    &:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: #f8f8f8;
      z-index: 2;
      opacity: 0.6;
    }

    &.drag-upload {
      border-color: #f8fbf6;

      &:after {
        background: #fff;
      }
    }
  }
}

.menu-link {
  padding: 10px 15px;
  border-bottom: 1px solid #dedede;

  ul {
    margin: 0px;
    display: flex;

    li {
      display: inline-block;
      color: #207ebb;
      font-size: 12px;
      margin-right: 20px;
      position: relative;

      &:after {
        content: "\e901";
        font-family: "primeicons" !important;
        position: absolute;
        right: -15px;
      }

      &:last-child {
        margin-right: 0;

        &::after {
          content: none;
        }
      }
    }
    .default {
      cursor: pointer;
    }
    .active {
      color: #404040;
    }
  }
}
/*Common CSS  for checkbox and radio button*/

//custom-radio
.custom-radio {
  padding-left: 22px;
  margin-bottom: 14px;

  input:checked ~ span {
    background-color: #fff;
  }

  span {
    background-color: #fff;
    border: 1px solid #207ebb;
    border-radius: 50%;

    &:after {
      top: 4px;
      left: 4px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #207ebb;
    }
  }
}

/* custom-checkbox */
.custom-checkbox-section {
  padding-left: 20px;
  margin: 0px;

  input {
    height: 0;
    width: 0;
  }

  input:checked ~ span {
    background-color: #207ebb;
    border-color: #207ebb;
  }

  span {
    background-color: #fff;
    border: 1px solid #d1d1d1;
    border-radius: 2px;

    &:after {
      left: 5px;
      top: 1px;
      width: 5px;
      height: 9px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.custom-checkbox-section,
.custom-radio {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 13px;
  color: #404040;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  input:checked ~ span:after {
    display: block;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
}

.editor-overlay .tox.tox-tinymce {
  border-color: #f8f8f8 !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f8f8f8 !important;
  border-color: #f8f8f8 !important;
}

.textDropdown.ui-dropdown {
  border: 0px !important;
  padding-right: 25px;
  width: auto !important;
  min-width: auto !important;
}

.textDropdown {
  .ui-dropdown-label {
    color: #207ebb !important;
    text-transform: lowercase;
    padding: 2px 0 0 6px !important;
  }

  .ui-dropdown-trigger-icon {
    color: #207ebb;
  }
}
.ui-state-disabled .ui-dropdown-trigger-icon {
  color: #d9d9d9 !important;
}
.in-valid-field input {
  border-color: #cc0000;
}

.disabled-radio,
.disabled-checkbox {
  pointer-events: none;
  color: #d9d9d9;

  span {
    border-color: #ccc;

    &:after {
      background: #ccc;
    }
  }
}

.ui-tooltip .ui-tooltip-text {
  font-size: 12px;
  padding: 5px 10px !important;
  border-radius: 4px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  background: #fff !important;
  color: #808080 !important;
  margin-bottom: 2px;
}

.ui-tooltip-left .ui-tooltip-arrow {
  border-left-color: #fff !important;
  margin-bottom: 2px;
}
.ui-tooltip-right .ui-tooltip-arrow {
  border-right-color: #fff !important;
  margin-bottom: 2px;
}
.ui-tooltip-bottom .ui-tooltip-arrow {
  border-bottom-color: #fff !important;
  margin-bottom: 2px;
}
.ui-tooltip-top .ui-tooltip-arrow {
  border-top-color: #fff !important;
  margin-bottom: 2px;
}

.ui-state-disabled .ui-dropdown-label {
  color: #000000 !important;
}

input[type="text"]:disabled {
  background: #fff;
  opacity: 0.5;
  border: 1px solid #dedede;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.add-program.custom-popup .ui-dialog-wrapper .ui-dialog .ui-dialog-content {
  padding: 0px !important;
}

.add-program.custom-popup .ui-dialog-wrapper .ui-dialog .ui-dialog-content p-footer {
  padding: 10px 25px;
}

.add-program.custom-popup .ui-dialog-wrapper .ui-dialog .ui-dialog-content .content {
  padding-bottom: 10px;
}
.ui-table-caption {
  padding: 5px 15px 0px !important;
  background-color: #fff;
  border-bottom: 1px solid #dedede !important;
}

.tabbing {
  background-color: #fff;
  padding-left: 15px;

  .alert-inner {
    margin: 0px;
    padding: 0px;
    list-style: none;

    li {
      display: inline-flex;
      align-items: center;
      color: #404040;
      font-size: 14px;
      margin-right: 31px;
      cursor: pointer;
      padding-top: 9px;
      padding-bottom: 9px !important;
    }

    .active {
      color: #207ebb !important;
      border-bottom: 2px solid #207ebb;
      padding-bottom: 0;
      cursor: pointer;
    }
  }
}
.edit-wrapper-icon {
  float: right;
}

.connection {
  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    li {
      display: block;
      margin-bottom: 15px;
      .connection-wrapper {
        position: relative;
        .badge {
          width: 28px;
          height: 28px;
          background-color: #f0f5eb;
          border-radius: 100%;
          text-align: center;
          line-height: 28px;
          padding: 0px;
          margin-right: 10px;
          color:#808080;
        }
        .name {
          font-size: 14px;
          .partner {
            color: #808080;
            font-size: 12px;
            margin-left: 16px;
          }
        }
        .edit {
          position: absolute;
          right: 0px;
          top: 0px;
        }
      }
    }
  }
  .add-group {
    border: 0px;
    background-color: transparent;
    color: #207ebb;
    padding: 0px;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
  }
}
.connection.text-connection{
  ul{
    li{
      .connection-wrapper {
        .badge {
          color:#808080;
          font-weight:400;
        }
        .name{
          color:#595D6E;
          font-size:15px;
        }
      }
    }
  }
  }
/*Common Selected Contacts*/
.ui-dropdown-filter-icon.pi-search:before {
  content: url(../../assets/images//search.svg) !important;
}

.ui-dropdown .ui-dropdown-panel {
  background-color: #fff !important;
}

.ui-dropdown-panel .ui-dropdown-filter {
  padding: 5px !important;
  border: 1px solid #f0f5eb;
}

.priority-dropdown span,
.priority-dropdown-filter span {
  padding-left: 13px;
  position: relative;
}
.priority-dropdown,
.priority-dropdown-filter {
  p-dropdownitem {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      left: 7px;
      top: 9px;
    }
  }
}

.priority-dropdown {
  p-dropdownitem {
    &:nth-child(1) {
      &:after {
        background-color: #d23200;
      }
    }
    &:nth-child(2) {
      &:after {
        background-color: #ece139;
      }
    }
    &:nth-child(3) {
      &:after {
        background-color: #207ebb;
      }
    }
  }
}

.priority-dropdown-filter {
  p-dropdownitem {
    &:nth-child(2) {
      &:after {
        background-color: #d23200;
      }
    }
    &:nth-child(3) {
      &:after {
        background-color: #ece139;
      }
    }
    &:nth-child(4) {
      &:after {
        background-color: #207ebb;
      }
    }
  }
}

.minimize-screen {
  width: 285px !important;
}
.view-template-wrapper .tox.tox-tinymce {
  height: 600px !important;
  border-radius: 5px !important;
}

.ui-minute-picker .pi {
  margin-left: 0px !important;
}
.ui-ampm-picker .pi {
  margin-left: 0px !important;
}
.task-section-container {
  position: relative;
  margin-bottom: 20px;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: flex-start;
    li {
      display: flex;
      align-items: flex-start;
      .ui-chkbox-icon.ui-clickable.pi.pi-check::before {
        left: 4px !important;
        top: -5px !important;
      }
      .task-heading {
        width: auto;
        position: relative;
        top: -3px;
        margin-left: 15px;
        margin-right: 10px;
      }
      .overdue {
        border-radius: 3px;
        font-size: 10px;
        top: -3px;
        display: inline-flex;
        position: relative;
        padding: 1px 4px;
      }
      .over-due {
        background: #d23200;
        color: #fff;
      }
      .other-status {
        background-color: #ddedf7;
        color: #3d5463;
      }
      label {
        font-size: 12px;
      }

      p-checkbox .ui-chkbox {
        margin-right: 12px;
        margin-left: 15px;
        display: flex;
      }
      .edit-alignment {
        position: absolute;
        right: 1px;
      }
    }
  }
  h6 {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: #207ebb;
    font-size: 12px;
    padding: 0px 0 8px 15px;
    margin: 0;
    .circle-gray {
      width: 19px;
      background-color: #dedede;
      height: 19px;
      border-radius: 100%;
      text-align: center;
      line-height: 19px;
      color: #404040;
      font-size: 8px;
      display: inline-block;
      margin-right: 5px;
    }
  }
  .single-line-dropdown-text {
    display: flex;
    justify-content: space-between;
    margin-left: 15px;
    position: relative;
    label {
      font-size: 12px;
      margin-bottom: 0px;
    }

    .date-time {
      color: #207ebb;
      margin-right: 94px;
      position: relative;
      font-size: 12px;
    }
  }
  &::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #dedede;
    position: absolute;
    bottom: -10px;
    left: 0px;
  }
  &:last-child {
    .task-section-container:after {
      content: none;
      background-color: transparent;
    }
  }
}
.btn-add-task {
  margin-top: 0px;
}
.btn-add-task .load-data {
  border: 0px;
  background-color: transparent;
  color: #207ebb;
  padding: 0px;
  font-size: 12px;
  &:hover {
    text-decoration: underline;
  }
}
.btn-add-task input[type="file"] {
  position: absolute;
  left: 0;
  opacity: 0;
  font-size: 14px;
  color: #404040;
}
.file-name {
  font-size: 14px;
  color: #404040;
  margin-left: 15px;
}
.extension-data {
  color: #404040;
  font-size: 12px;
}
.inner-data {
  color: #404040;
  font-size: 12px;
  padding-left: 15px;
  min-width: 300px;
  width: 300px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: bottom;
}

.inner-data-ticket {
  min-width: 315px;
  width: 315px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: bottom;
}
.inner-data-doc-table {
  min-width: 200px;
  width: 200px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: bottom;
}
.trans-data-ticket-table {
  min-width: 170px;
  width: 170px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: bottom;
}
.docs-data-link-ext-table {
  display: inline-block !important;
  margin-left: 5px !important;
}
.setTopDoc{
  position: relative;
  top: 1px;
  right: -4px;
}
  .docs-data-ticket-table {
  min-width: 105px;
  width: 105px;
  display: inline-block !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: bottom;
}
.download-icon {
  padding-right: 15px;
}

.notify-body {
  background: #f6f7fb;
  border-radius: 5px;
  padding: 10px;
  position: relative;
  left: 4px;
  width: 589px;
}
.page-wrapper .gallery-mock-popup .ui-dialog-resizable .ui-dialog-content{
  overflow: hidden!important;
}
.notify-item {
  margin-right: 0px;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0px;
  }
  .col-11 {
    flex: 0 0 84.333333%;
    max-width: 84.333333%;
    margin-left: 27px;
  }
}

.notify-icon {
  width: 40px;
  height: 40px;
  background-color: #e1e1e1;
  color: white;
  border-radius: 50%;
  text-align: center;
  text-align: -webkit-center;
  border: 1px solid #f0f1f7;
  right: 6px;
  position: relative;
  z-index: 99;
}

.notify > a:hover {
  color: #669933;
  text-decoration: underline !important;
}
.not-badge {
  background-color: white;
  position: relative;
  color: grey;
  bottom: 17px;
  left: 22px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  text-align: center;
  line-height: 2;
  box-shadow: 2px 2px 6px -1px rgba(0, 0, 0, 0.57);
  z-index: 999;
  img {
    position: absolute;
    top: 6px;
    left: 0;
    right: 0;
    margin: 0 auto;
    align-items: first baseline;
    display: flex;
  }
}

.port-body {
  background: #ffffff;
  padding: 20px 15px;
}
.drop-body{
  padding: 20px 17px;
}
.new-tag,
.new-task {
  padding: 1px 4px;
  color: white;
  border-radius: 5px;
  font-size: 0.7rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.new-tag {
  background-color: #669933;
}

.new-task {
  background-color: #cc0000;
}
label {
  color: #404040;
  font-size: 12px;
}
.noti-para {
  font-size: 13px;
  color: #404040;
}
.notify-body {
  padding: 5px 10px 10px 10px !important;
}
.name-no {
  top: 19%;
  position: relative;
  font-size: 16px;
}
.three-dot {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 15px !important;
}
.content-wrapper {
  height: calc(100vh - 200px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 15px;
}
.content {
  padding: 0 15px 0 0px;
}

.main-content {
  .form-group {
    margin-bottom: 15px !important;
  }
  padding-right: 15px;
  label {
    font-size: 13px;
    color: #404040;
  }
  .form-control {
    height: 28px;
    font-size: 12px;
    border: 1px solid #dedede;
    text-align: justify;
  }
  .ui-dropdown {
    border: 1px solid #dedede !important;
    width: 100% !important;
    height: 28px !important;
  }
  .ui-dropdown .ui-dropdown-label {
    padding-top: 4px;
  }
  input:focus {
    box-shadow: none;
    border: 1px solid #dedede;
  }
  textarea:focus {
    box-shadow: none;
    border: 1px solid #dedede;
  }

  .btn-save {
    color: #207ebb;
    background-color: #207ebb1a;
    padding: 7px 21px 7px 21px;
    border-radius: 3px;
    margin-right: 15px;
    font-size: 12px;
  }
  .btn-save:hover {
    color: #f5f5f5;
    background-color: #207ebb;
  }
  input:focus {
    box-shadow: none;
    border: 1px solid #dedede;
  }
  .btn-cancel {
    cursor: pointer;
    margin-top: 10px;
    padding: 7px 16px 7px 16px;
    font-size: 12px;
    border-radius: 3px;
    background: #f5f5f5;
    color: #808080;
  }
  .btn-cancel:hover {
    color: #f5f5f5;
    background: #808080;
  }
  .not-editable {
    cursor: not-allowed;
  }
  .col-8 {
    padding-right: 3px;
  }
  .col-4 {
    padding-left: 3px;
  }
  .checked-text {
    margin-left: 5px;
    position: relative;
  }
  .btn-save {
    color: #207ebb;
    background-color: #207ebb1a;
    padding: 7px 21px 7px 21px;
    border-radius: 3px;
    margin-right: 15px;
    font-size: 12px;
  }
  .btn-save:hover {
    color: #f5f5f5;
    background-color: #207ebb;
  }
  .chckbox {
    padding-top: 10px;
  }
  .display-area {
    height: 300px;
    width: 300px;
    margin-bottom: 5px;
    border: 1px solid #dedede;
  }
  .btn-remove {
    cursor: pointer;
    margin-top: 10px;
    padding: 7px 16px 7px 16px;
    font-size: 12px;
    border-radius: 3px;
    background: #f5f5f5;
    color: #808080;
  }
  .btn-remove:hover {
    color: #f5f5f5;
    background: #808080;
  }
  .file-upload {
    font-size: 12px;
    display: block;
  }
  .ui-dropdown .ui-dropdown-trigger .ui-dropdown-trigger-icon:before {
    content: url("../../assets/images/down-arrow.png");
  }
  textarea {
    height: 100px !important;
    resize: none !important;
  }
}

.list-filter .c-clear {
  display: none !important;
}

.profile-data {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 1px solid #c4d5df;
  .profile-pic {
    flex-shrink: 0;
    -webkit-flex-shrink: 0;
    padding: 10px;
  }
}
.edit-wrapper {
  margin-right: 10px;
  img {
    cursor: pointer;
    margin-top: 2px;
  }
  .edit-tooltip {
    margin-right: 10px;
    cursor: pointer !important;
  }
}

input[type="file"] {
  cursor: pointer;
}

.tag__text {
  font-size: 12px !important;
}

.vaildation-area tag {
  background-color: #fff6f6 !important;
  border-radius: 5px !important;
  padding: 0 10px !important;
}
.intl-tel-input.separate-dial-code .selected-flag, .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 .selected-flag, .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 .selected-flag, .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 .selected-flag {
  width: 52px !important;
}
.vaiidate-field{
  font-size: 12px !important;
}
.required-field{
  color:red;
}
.own-valid-area ion-item{
  border-bottom: 1px solid #dedede;
}
.own-save-note{
  width: 70px;
    height: 27px;
    font-size: 12px!important;
    padding:0px!important
}
.own-email-ngx.contact-phone .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input{
  padding-top: 0px!important;
}
.add-service-popup .ui-dialog-content ion-label {
  font-size: 13px!important;
  color: grey!important;
  margin: -6px 0 0!important;
}
.list-inline.tabing.own-list-wrap li{
  margin-right: 0px!important;
}
.list-inline.tabing.own-list-wrap {
  display: flex;
  justify-content: space-between;
}
.activity-wrapper-detail .port-body .notify-item.line-in:last-of-type::after{
  display: none!important;
  content:'';
}
.page-wrapper .ui-dialog-resizable .ui-dialog-content {
  overflow: auto !important;
}
.own-calender-warp .ui-calendar .ui-datepicker{
 width:auto!important;
 min-width: auto!important;
 max-width: auto!important;
}
.own-calender-warp.calender span{
  width: 100%!important;
}
.own-calender-warp.calender{
  border:none!important
}
.own-calender-warp.calender input{
  width: 100% !important;
border: none;
border-bottom: 1px solid #dedede !important;
border-right: none !important;
border-top: none !important;
border-left: none !important;
height: 26px !important;
border-radius: 0px;
font-size: 13px;
    padding-left: 0!important;
    width: 100%;
}
.task-icons-delete{
  width:10px!important
}
.contact-page-wrapper .owwn-table-call div{
  right: -9px !important;
    top: -10px !important;
    position: absolute!important;
    font-size: 10px!important;
}
.follow-calender button{
  display: none!important;
}
.contact-page-wrapper p-dropdown.service-drop .ui-dropdown {
  width: 100% !important;
    border: none!important;
    border-bottom: 1px solid #dedede !important;
    border-right: none !important;
    border-top: none !important;
    border-left: none !important;
    height: 26px !important;
    border-radius: 0px;
}
.contact-page-wrapper p-dropdown.service-drop label{
  width:100%!important;
}
.add-category-content{
  padding-bottom: 20px!important;
}
.pointer-arrow .tag-body.tag-warpper{
  padding-top:4px!important
}
.pointer-arrow.own-tag-blog .collapse a{
  margin-bottom: 10px;
}
.edit-gallery-view .ck-file-dialog-button{
  display: none!important;
}
.name_texts-warpper{
  width: 83px!important;
  display: inline-block;
}
.image-upload-popup.gallery-mock-popup .ui-dialog-wrapper .ui-dialog{
  width:1240px!important;
}
.interaction-bar-inner-right .show-box.more-filter-drop{
  width:170px!important;
}
.interaction-bar-inner-right .show-box.more-filter-drop .more-light{
  opacity: 0.6!important;
}
.contact-subheader.filter-max  li .w-12 {
  opacity: 0.4!important;
}
.t-body-wrap .custom-drop-menu .ui-dropdown{
  width:110px!important;
}
.custom-drop-menu .ui-dropdown-label-container .ui-inputtext {
  width: auto!important;
}
.name_text .address-wrapper.white-space-warp{
  white-space: normal!important;
}
.name_text .name-create-warp{
  width:60px!important;
  display: inline-block!important;
  white-space: normal!important;
}
.show-more-notification.more-filtering-options{
  max-width: 148px!important;
  width:148px!important
}
#edit-contcat-wrap .more-filtering-options .delete-playbook-wrapper li {
  height: 25px !important;
  padding-top: 0px!important;
  display: flex!important;
  align-items: center;
}
.edit-wrapper .more-filtering-options li span {
  margin-left: 11px;
   position: inherit!important;
 top:auto!important;
}
.filter-wrapper .contact-subheader.filter-max .assign_to_pop{
  opacity: 1!important;;
}
.vaildation-area.dropdown-top .min-input-text input{
  height: auto!important;
}
.interaction-bar-inner-right .show-box.more-filter-drop .upload-menu span img{
  width:15px!important;
  height:13px!important;
}
.light-opc-icon{
  opacity: 0.5;
}
.upload-file-local{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.updloade-centerlly{
  display:flex;
  justify-content: center;
  align-items: center;
}
.gallery-mock-popup .custom-drops .drop-wrapper {
  margin-right: 10px;
}
.gallery-mock-popup .images-wrapper figure{
  height: auto!important;
}
.gallery-tabset li a.nav-link.active{
    background: #f3f6f9;
    border-bottom: 4px solid #207ebb;
    color: #207ebb!important;
    display: inline-block;
    cursor: pointer;
    font-size: 13px;
    padding: 5px 6px 4px 6px;
    color: #808080;
    border-radius: 4px;
    border-top:none;
    border-left:none;
    border-right:none;
 }
 .gallery-tabset a.nav-link{
  display: block;
  cursor: pointer;
  font-size: 13px;
  padding: 5px 6px 4px 6px;
  color: #808080;
  border-radius: 4px;
 }
 .gallery-tabset ul{
   border:none;
 }
 .upload-file-local{
  position: relative;
  top: 126px;
 }
 .gallery-mock-popup .ui-dialog-footer{
  padding:8px 1rem 8px 1rem!important
}
.contact-subheader.filter-max .selected-all-records{
  font-size: 13px!important;
}
.program-list-drop .ui-dropdown{
  width: 100% !important;
    border: none;
    border-bottom: 1px solid #dedede !important;
    border-right: none !important;
    border-top: none !important;
    border-left: none !important;
    height: 26px !important;
    border-radius: 0px;
}
.program-list-drop .ui-dropdown .ui-dropdown-label{
  font-size: 13px;
  padding-left: 0px !important;
  width: 100%;
}
.contact-subheader.filter-max .task-icons-delete{
  width:13px!important;
}
.contact-page-wrapper p-dropdown.service-drop .ui-dropdown .ui-dropdown-label{
  font-size: 13px;
  padding-left: 0px !important;
  width: 100%;
}
.schedule_reoccur .ui-calendar-w-btn input {
  width: 100%!important;
  border-top: none!important;
  border-left: none!important;
  border-right: none!important;
  padding: 0 0 6px 0!important;
  font-size: 13px!important;
  color: #404040 !important;
  border-bottom: 1px solid #dedede!important;
}
.schedule_reoccur .ui-calendar {
  width: 100%!important;
}
.contact-page-wrapper .date_time_wrap{
  width:60px!important;
  display:inline-block!important;
  white-space: normal!important;
}
.align-left-documents{
  padding-left:0px!important
}

.view-checklist-wrapper span.align-left-documents p-checkbox:first-child{
 margin-left:0px!important
}
.own-header_checklist{
  padding-left: 0px!important;
}
.head-btn-wrap.save_right_move{
  padding-left: 25px!important;
}
.ui-dropdown-panel .ui-dropdown-filter-container .ui-dropdown-filter-icon {
  position: absolute!important;
  top: 14px!important;
  right: 1em!important;
}
.pointer-arrow .own-drop-private{
  padding-top:0px!important;
  padding-left: 0px!important;
  padding-right: 0px!important;
}
.pop-transaction.stickedFooter .user-input.prefilled_agent input.native-input.sc-ion-input-md{
  position: relative!important;
  top: 3px!important;
  padding-bottom: 5px!important;
}
.pop-transaction .county_label_desc{
  font-size: 13px!important;
  padding-left: 0px !important;
  width: 100%;
  color:#808080!important;
  margin-bottom: 15px!important;
}
.pop-transaction .top-warpper-tag.top_label_wrap{
  position: relative;
  top:-15px
}
.activity-wrapper-detail.content_after_warp .notify-item.wrapp-content:nth-of-type(2)::after {
    content: ""!important;
    position: absolute!important;
    top: 34px!important;
    left: 30px!important;
    width: 1px!important;
    height: 100%!important;
    background-color: #dedede!important;
    display: block!important;
    border-bottom: 1px solid red!important;
}
.contact-page-wrapper .more-filtering-options.service-provider{
  max-width: 162px !important;
  width: 162px !important;
}
.show-more-notification.transaction-notifications{
  max-width: 170px !important;
  width: 170px !important;
}
.filter-date-wrapper #created-date .ui-datepicker{
  top:93px !important;
}
 .ui-datepicker.ui-helper-clearfix{
  transform: translateY(3px) !important;
.uploaded_view .view-comment-cions{
  height:11px!important;
  opacity: 0.5!important;
 }
}
.check-full-wrapper .view-table-wrapper.height_autoable{
  height: auto!important;
}
.check-full-wrapper .view-table-wrapper.height_autoable .page-wrapper{
  height: auto!important;
}
.check-full-wrapper .view-table-wrapper.height_autoable .saved-wrapper{
  position: relative;
}
.idx_add_filters .check-box-wrapper .ui-chkbox-icon.ui-clickable.pi.pi-check::before {
  top: 0px !important;
}
.own-multiselect-border.multiple-select .ui-multiselect {
  border-left:none!important;
  border-right:none!important;
  border-top:none!important;
}
.active-doc{
  background: #add8e63d;
  color: #1a73e8!important;
}
.dropdown-top.modify-status .selected-list .c-btn {
  padding: 5px 0;
  border-bottom: 1px solid #dedede;
  border-radius: 0px;
}
.dropdown-top.modify-status .selected-list .c-btn span {
  color: #808080;
  padding: 0px !important;
}
.dropdown-top.modify-status .dropdown-list {
  width: 460px !important;
}
.dropdown-top.modify-status .arrow-up {
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 15px solid #fff;
  margin-left: 15px;
  position: absolute;
  top: 0;
  display: none !important;
}
.dropdown-top.modify-status .list-area {
  border: 0px solid #ccc !important;
  border-radius: 3px;
  background: #fff;
  margin: 0;
  padding: 14px 0 10px 10px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.4);
}
.dropdown-top.modify-status .select-all {
  padding: 2px 0 0 10px;
  border-bottom: 0px solid #ccc;
  text-align: left;
  position: absolute;
  z-index: 9;
}
.dropdown-top.modify-status .pure-checkbox input[type="checkbox"] + label:before {
  box-sizing: content-box;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  text-align: center;
  transition: 0.4s;
  border-radius: 3px;
  border: 1px solid #dedede;
}
.dropdown-top.modify-status .pure-checkbox input[type="checkbox"] + label > span {
  display: none;
}
.dropdown-top.modify-status .list-filter {
  position: relative;
  height: 30px;
  border: 0px;
  padding: 0 35px 0 42px;
  margin-bottom: 15px;
}
.dropdown-top.modify-status .list-filter .c-search {
  position: absolute;
  top: 4px !important;
  right: 10px !important;
  left: auto !important;
  width: 15px;
  height: 15px;
  display: none;
}
.dropdown-top.modify-status .list-filter .c-clear {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 15px;
  height: 15px;
  display: none;
}
.dropdown-top.modify-status .custom-multiselect .list-filter input {
  font-size: 13px;
  color: #808080;
  border: 1px solid #dedede;
  padding: 0 0 2px 7px;
}
.dropdown-top.modify-status .pure-checkbox .item-name {
  color: #404040;
  font-size: 13px;
  margin: 0;
  cursor: pointer;
  text-transform: capitalize;
}
.dropdown-top.modify-status .vertical.selfScroll .scrollable-content li {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.dropdown-top.modify-status .list-filter input {
  font-size: 13px;
  color: #808080;
  border: 1px solid #dedede;
  padding: 0 0 2px 7px;
}
.activity-feed.transaction-detail .activity-wrapper .port-body .notify-item.line-in:last-child::after{
  width:1px!important;
}
.main-content.main-setting{
  .form-group {
    margin-bottom: 25px !important;
  }
  padding-right: 15px;
  .label {
    font-size: 14px;
    color: #404040 !important;
  }
  .form-control {
    height: 30px;
    font-size: 12px;
    border: 1px solid #dedede;
    text-align: justify;
  }
  .ui-dropdown {
    border: 1px solid #dedede !important;
    width: 100% !important;
    height: 30px !important;
  }
  .ui-dropdown .ui-dropdown-label {
    padding-top: 5px;
  }
  input:focus {
    box-shadow: none;
    border: 1px solid #dedede;
  }
  textarea:focus {
    box-shadow: none;
    border: 1px solid #dedede;
  }

  .btn-save {
    color: #207ebb;
    background-color: #207ebb1a;
    padding: 7px 21px 7px 21px;
    border-radius: 5px;
    margin-right: 15px;
    font-size: 14px;
  }
  .btn-save:hover {
    color: #f5f5f5;
    background-color: #207ebb;
  }
  input:focus {
    box-shadow: none;
    border: 1px solid #dedede;
  }
  .btn-cancel {
    cursor: pointer;
    margin-top: 10px;
    padding: 7px 16px 7px 16px;
    font-size: 14px;
    border-radius: 3px;
    background: #f5f5f5;
    color: #808080;
  }
  .btn-cancel:hover {
    color: #f5f5f5;
    background: #808080;
  }
  .not-editable {
    cursor: not-allowed;
  }
  .col-8 {
    padding-right: 3px;
  }
  .col-4 {
    padding-left: 3px;
  }
  .checked-text {
    margin-left: 5px;
    position: relative;
  }
  .btn-save {
    color: #207ebb;
    background-color: #207ebb1a;
    padding: 7px 21px 7px 21px;
    border-radius: 3px;
    margin-right: 15px;
    font-size: 14px;
  }
  .btn-save:hover {
    color: #f5f5f5;
    background-color: #207ebb;
  }
  .chckbox {
    padding-top: 10px;
  }
  .display-area {
    height: 300px;
    width: 300px;
    margin-bottom: 5px;
    border: 1px solid #dedede;
  }
  .btn-remove {
    cursor: pointer;
    margin-top: 10px;
    padding: 7px 16px 7px 16px;
    font-size: 12px;
    border-radius: 3px;
    background: #f5f5f5;
    color: #808080;
  }
  .btn-remove:hover {
    color: #f5f5f5;
    background: #808080;
  }
  .ui-dropdown .ui-dropdown-trigger .ui-dropdown-trigger-icon:before {
    content: url("../../assets/images/down-arrow.png");
  }
 
}
.own-display-block{
  display:inline-block;
}
.footer-content.main-setting .contact-footer-btn{
  margin-bottom:0 !important;
  
}
.footer-content.main-setting .btn-cancel{
margin-top:5px;
}
.main-content.footer-team-content .ui-dropdown{
  height:24px !important;

  width:58px !important;
  margin-right:15px;
.dropdown-top.modify-status .group-contacts .list-area{
  max-height:200px!important;
  height:auto;
  overflow: auto;
}
.contact-icon-drop .name-create-warp.own-date-width{
  width:80px!important
}
}
.custom-popup .re-occur-schedule .ui-calendar.ui-calendar-w-btn {
  display: block;
  width:100%;
}
.re-occur-schedule .ui-button-icon-only {
  position: absolute;
  right: -4px;
  background-color: transparent;
}
.filter_modify_schedule .vaildation-area.p-dropdown-top {
  display:none!important
}

.filter_modify_schedule .program-form {
  padding: 10px 0px!important;
  max-height: 200px!important;
  overflow-y: auto!important;
}
.filter_modify_schedule .validation-area.own-validate-area {
  display: none!important;
}
.filter_modify_schedule p-footer{
  display:none!important;
}
.filter_modify_schedule .content.program-content {
  margin-top: -25px!important;
}
.filter_modify_schedule input.native-input.sc-ion-input-md{
  padding-bottom: 6px!important;
}
.filter_modify_schedule .custom-radio.own-template-set{
  display:block!important
}
.purchase-wrapper.img_warper img{
  margin-right: 10px!important;
}
.send-middle .own-date-warppe .ui-calendar:after {
  content: "\e902"!important;
  font-family: primeicons!important;
  position: absolute;
  top: 4px;
  right: -20px;
  color: #207ebb!important;
  font-size: 14px;
}
.name_text .name-create-warp.date-wrapper-section{
  width: 100% !important;
  display: block  !important;
  white-space: none !important;
}
.own-calender-hide .ui-datepicker-trigger {
  display:none!important;
}
  .own-edit-tag .ui-dialog-wrapper .ui-dialog{
    margin: 100px 0px!important;
  }
  .own-edit-tag .label-floating.sc-ion-label-md-h{
    margin: -11px 0 0 0;
  }
.metronic-design .button_slide.slide_right{
  background-color: #f0f5eb!important;
  border: 1px solid #f0f5eb!important;
  color: #6a9f3a!important;
  transition: all .3s ease!important;
  margin: 0 .1rem!important;
  padding: 5px 4px!important;
  height: auto!important;
  border-radius: 3px!important;
}
.metronic-design .button_slide.slide_right:hover{
  color: #f0f5eb!important;
    background: #6a9f3a!important;
    border-radius: 3px;
}
.contact-page-wrapper .approval-drop-cut .ui-dropdown-label-container{
  white-space: nowrap!important;
  width: 62px!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
}
.popup-element.custom-popup.blog-layout-popup .ui-dialog-footer{
    padding-top: 0px!important;
}
.own-custom-chekwarp .ui-chkbox-icon.ui-clickable.pi.pi-check::before{
  top:-4px!important;
}
.contact-main-page .own-discription-items{
  display:flex;
  justify-content: space-between;
  align-items: center;
}
.image_cropper_class img.source-image{
  width:200px!important;
  height:200px!important;
}
.pointer-arrow.agent .own-profile-settings{
  height:auto!important;
  margin-bottom: 1rem!important;
}
.own-img-popup .profile-items .figure-image{
  width:70px;
  height:70px;
  max-width: 100%;
}
.checkbox-relative .profile-items .own-custom-chekwarp{
  position: relative;
  bottom:10px;
}
