.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.4);
  overflow-x: hidden;
  transition: 0.5s;
}

.personal-inner {
  position: fixed;
  top: 0px;
  width: 446px;
  right: 0px;
  background-color: #fff;
  top: 0px;
  min-height: 100vh;
  z-index: 1000;

  .personal-header {
    padding: 15px 0px 15px 15px;
    border-bottom: 1px solid#f0f5eb;
    position: relative;
    z-index: 1;

    .header-title {
      font-size: 16px;
      color: #404040;
      margin-bottom: 0px;
      font-weight: normal;
    }

    .close {
      width: 12px;
      position: absolute;
      top: 9px;
      right: 19px;
      cursor: pointer;
    }
  }

  .personal-body {
    padding: 20px 15px 0px 15px;

    .personal-inner-wrapper {
      margin-bottom: 25px;
      position: relative;
    }

    .detail-label {
      margin-bottom: 5px;
      font-size: 12px;
      display: block;
    }

    .detail-text {
      display: block;
      margin: 0;
      font-size: 14px;
    }
  }

  .personal-footer {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 18px;
  }
}

.detail-dropdown.ui-dropdown {
  width: 100% !important;
  height: 30px !important;
}

.detail-dropdown.ui-dropdown .ui-dropdown-label {
  line-height: 26px !important;
  min-height: 29px;
}

.uploaded-images {
  margin: 35px 0 0 0;
  display: flex;
  align-items: center;

  li {
    width: 70px;
    height: 70px;
    margin-right: 10px;
    border: 1px solid #dedede;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    position: relative;

    span {
      cursor: pointer;
      color: #fff;
      font-size: 13px;
      position: absolute;
      width: 0;
      height: 0;
      background: rgba(0, 0, 0, .6);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      -webkit-transition: all ease 0.2s;
      -moz-transition: all ease 0.2s;
      -ms-transition: all ease 0.2s;
      transition: all ease 0.2s;
      -webkit-transform-origin: center center;
      -moz-transform-origin: center center;
      -ms-transform-origin: center center;
      transform-origin: center center;
      overflow: hidden;
    }

    img {
      width: 100%;
      height: 100%;
    }

    &:hover {
      span {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.attachment-list {
  margin: 0px;

  li {
    a {
      color: #207ebb;
      display: flex;
      align-items: baseline;
      cursor: pointer;

      img {
        margin-right: 10px;
      }

      i {
        font-size: 16px;
        font-style: normal;
      }
    }

    &:first-child {
      padding-left: 0px !important;
      line-height: inherit !important;
    }

    &:last-child {
      padding-left: 23px;
      line-height: 14px;
    }

    &.hidden-attachment {
      display: none;
    }
  }

  &.attachment-list-detail {
    li {
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      span {
        &.image {
          width: 50px;
          height: 50px;
          padding: 10px;
          border: 1px solid #dedede;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      &:last-child {
        line-height: inherit;
        padding: 0px;
        margin: 0px;
      }
    }
  }
}

.comments-list {
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #f0f5eb;
  border-radius: 5px;
  font-size: 13px;

  li {
    padding: 10px 10px 0 10px;

    &>span {
      display: block;
      color: #207ebb;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        &:first-child {
          background: #eee;
          color: #808080;
          padding: 5px 10px;
          border-radius: 4px;
        }
        &:last-child {
          white-space: nowrap;
          padding-left: 10px;
        }
      }
    }
  }
}